@use "definitions" as *;

.sort-by-column {
    position: relative;
    cursor: pointer;
}

.sort-by-column:hover {
    .sort-by-column__indicator {
        width: 16px;
    }
    .sort-by-column__indicator div {
        display: block;
    }
}

.sort-by-column__indicator {
    position: absolute;
    height: 28px;
    width: 0;
    bottom: 0px;
    right: 0px;
}

.sort-by-column__indicator div {
    width: 14px;
    height: 14px;
    border-radius: 7px;
    background-color: lg-color(--gray-color-5);
    position: absolute;
    bottom: 6px;
    right: 0px;
    display: none;
    cursor: pointer;

    &::before {
        border-width: 0 4px 4px 4px;
        border-color: transparent;
        border-bottom-color: lg-color(--gray-color-40);
        border-style: solid;
        width: 0px;
        height: 0px;
        position: absolute;
        left: 3px;
        top: 2px;
        display: block;
        content: " ";
        overflow: hidden;
    }
    &::after {
        border-width: 4px 4px 0px 4px;
        border-color: transparent;
        border-top-color: lg-color(--gray-color-40);
        border-style: solid;
        width: 0px;
        height: 0px;
        position: absolute;
        left: 3px;
        bottom: 2px;
        display: block;
        content: " ";
        overflow: hidden;
    }
}

.sort-by-column--asc {
    &:not(:hover) .sort-by-column__indicator div {
        background-color: transparent;
    }
    .sort-by-column__indicator div {
        display: block;

        &::before {
            border-bottom-color: lg-color(--base-60);
            top: 4px;
        }
        &::after {
            display: none;
        }
    }
}

.sort-by-column--desc {
    &:not(:hover) .sort-by-column__indicator div {
        background-color: transparent;
    }
    .sort-by-column__indicator div {
        display: block;

        &::before {
            display: none;
        }
        &::after {
            border-top-color: lg-color(--base-60);
            bottom: 5px;
        }
    }
}
