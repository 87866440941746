@use "styles/logos";
@use "styles/sort-by-indicator";
@use "definitions" as *;

$min-body-width: 1024px;

.body__content {
    padding: 0;
}

.general-page-content {
    padding: 0 var(--spacing5);
}

.breadcrumb-row {
    padding: var(--spacing2) var(--spacing5);
    width: calc(100% - var(--spacing7));
    margin-bottom: 0;
    border-bottom: 1px solid lg-color(--gray-color-20);
}
